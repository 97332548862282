<template>
  <div class="table_wrapper">
    <h5>Coming Soon</h5>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Orders",
  data() {
    return {
      data: null,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
